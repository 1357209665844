import styled from 'styled-components';

export const Container = styled('div')`
    width: 100%;
    height: 100%;
    overflow: auto;
    display: flex;
    flex-direction: column;
`;

export const AppContent = styled('div')`
    width: 100%;
    height: auto;
    padding: 50px 0;
    flex: 1;
    max-width: 1148px;
    margin: 0 auto;
`;
