import Request from '../utils/Request';

/**
 * Pega informações do usuario
 * @return {Promise<boolean|*>}
 */
export async function getUsuario() {
    const req = new Request();
    const user_id = req.setRequest('login', 'dadosUsuario');

    const result = await req.execute();

    if (result !== undefined) {
        const user = result[user_id];

        if (user !== null && user !== undefined) {
            return user;
        }
    }

    return false;
}
