import Request from '../utils/Request';

export const listar = async () => {
    const req = new Request();

    const req_listar = req.setRequest('configuracoes', 'listar', {});
    const res = await req.execute();

    if (res && res[req_listar]) {
        return res[req_listar];
    }

    return {};
};

export const selectIndex = async (index) => {};
